import { useSuspense } from '@data-client/react'
import { PropsWithChildren, createContext, useContext } from 'react'
import { Company, CompanyResource } from '../datasource/company'
import { User, UserResource } from '../datasource/user'
import { useAuth } from './AuthProvider'

type AccountContextProps = {
  user: User
  company: Company
  pvAdmin: boolean
}

const defaultValues = {
  user: new User(),
  company: new Company(),
  pvAdmin: false,
}

const Account = createContext<AccountContextProps>(defaultValues)

function AccountProvider({ children }: PropsWithChildren) {
  const { companyId, userId } = useAuth()
  const user = useSuspense(UserResource.get, { id: `${userId}` })
  const company = useSuspense(CompanyResource.get, { id: `${companyId}` })

  return (
    <Account.Provider
      value={{
        user,
        company,
        pvAdmin:
          (companyId === 72 || companyId === 1) &&
          (user.email.includes('@peoplevine.com') || user.email.includes('@sciant.com')),
      }}
    >
      {children}
    </Account.Provider>
  )
}

const useAccount: () => AccountContextProps = () => useContext(Account)

export { AccountProvider, useAccount }
