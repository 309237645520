import { PaginatedQuery } from 'src/sdk/types/api'
import { ApiEndpoint, ApiEntity } from './ApiResource'
import { GlobalNetwork } from './globalNetwork'

type CompanyStatus = 'active' | 'inactive'

type CompanyFilter = {
  order_by?: string
  search?: string
  status?: CompanyStatus
} & PaginatedQuery

export type CompanyVersion = 'legacy' | 'enterprise' | 'nextgen' | 'unknown'

class Company extends ApiEntity {
  readonly id: number = 0
  readonly name: string = ''
  readonly logo_url: string = ''
  readonly icon_url: string = ''
  readonly biography: string = ''
  readonly website: string = ''
  readonly email: string = ''
  readonly phone: string = ''
  readonly address: string = ''
  readonly address2: string = ''
  readonly city: string = ''
  readonly state: string = ''
  readonly zip_code: string = ''
  readonly country: string = ''
  readonly full_address: string = ''
  readonly user_id: number = 0
  readonly foreground_color: string = ''
  readonly background_color: string = ''
  readonly subscription_id: number = 0
  readonly timezone_offset: string = ''
  readonly timezone_id: string = ''
  readonly parent_company_id: number = 0
  readonly status: CompanyStatus = 'active'
  readonly createdOn: string = ''
  readonly currency: string = ''
  readonly version: CompanyVersion = 'unknown'
}

const List = new ApiEndpoint({
  path: '/company',
  method: 'GET',
  searchParams: {} as CompanyFilter,
  schema: [Company],
  // update: (newUserId: string) => ({
  //   [List.key()]: (users = []) => [newUserId, ...users],
  // }),
})

const Get = new ApiEndpoint({
  path: '/company/:id',
  method: 'GET',
  schema: Company,
})

const GlobalNetworks = new ApiEndpoint({
  path: '/company/global-networks',
  method: 'GET',
  schema: [GlobalNetwork],
})

const CompanyResource = {
  list: List,
  get: Get,
  globalNetworks: GlobalNetworks,
}

export { Company, CompanyResource }
