import { createResource, Entity, Resource, RestEndpoint, RestGenerics, Schema } from '@data-client/rest'
import { AxiosError } from 'axios'
import { ApiError } from '../api/error'
import AxiosService from '../services/AxiosService'
import { removeEmptyParams } from '../helpers/objects'


export abstract class ApiEntity extends Entity {
  readonly id: number | string = 0
  pk() {
    return `${this.id}`
  }
}

export class ApiEndpoint<O extends RestGenerics = any> extends RestEndpoint<O> {
  urlPrefix = `${import.meta.env.REACT_APP_API_URL}`

  url<A extends Record<string, unknown>>(...args: A[]) {
    const cleanedParams = args ? args.map((value) => removeEmptyParams(value)) : ([] as A[])
    return super.url(...cleanedParams as never)
  }

  async fetchResponse(input: RequestInfo, init: RequestInit) {
    const url = typeof input === 'string' ? input.replace('/the_only_one', '') : input.url

    const { privateInstance } = AxiosService()
    return await privateInstance
      .request({
        url: url,
        method: init.method,
        data: init.body,
      })
      .then((response) => {
        if (!response.status.toString().startsWith('2')) {
          throw new ApiError(response.status, JSON.parse(response.data))
        }
        if (!response.data) return []
        return response.data ?? []
      })
      .catch((err: AxiosError) => {
        const response = err?.response
        throw new ApiError(response?.status, response?.data)
      })
  }

  parseResponse(response: any) {
    return response
  }
}

interface ApiResourcePaths {
  list?: string
  get?: string
  partialUpdate?: string
}

export function CreateApiResource<U extends string, S extends Schema, P extends ApiResourcePaths>({
  path,
  schema,
  Endpoint = ApiEndpoint,
  paths = {},
  optimistic = false,
  paginationField,
}: {
  readonly path: U
  readonly schema: S
  readonly Endpoint?: typeof ApiEndpoint
  readonly paths?: Partial<P>
  readonly optimistic?: boolean
  readonly paginationField?: string
}) {
  const base = createResource({ path, schema, Endpoint, optimistic: optimistic, urlPrefix: import.meta.env.REACT_APP_API_URL })

  const partialUpdate = new ApiEndpoint({
    path: paths.partialUpdate ?? base.partialUpdate.path,
    method: 'PATCH',
    body: {} as any,
    schema: schema,
    process(value): S { return value }
  })

  const list = new ApiEndpoint({
    path: paths.list ?? base.get.path.replace('/:id', ''),
    method: 'GET',
    body: undefined,
    schema: [schema],
    paginationField: paginationField,
    process(value): S[] { return value }
  })

  return {
    ...base,
    list,
    partialUpdate,
  }
}
