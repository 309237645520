import { useController, useDLE } from '@data-client/react'
import { Badge, Card, Col, Input, Modal, Row, Spin, Tag, Typography } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { HorizontalSpace, VerticalSpace } from 'src/sdk/components/space'
import { CompanyResource } from 'src/sdk/datasource/company'
import { useAuth } from 'src/sdk/providers/AuthProvider'
import { withPrefix } from 'src/sdk/providers/ConfigProvider'
import './CompanySelect.scss'

type CompanyItem = {
  id: number
  name: string
  icon_url: string
  logo_url: string
  status: string
}

interface ICompanySelect {
  onSelect?: () => void
}

const CompanySelect = ({ onSelect }: ICompanySelect) => {
  const navigate = useNavigate()
  const { resetEntireStore } = useController()
  const { switchCompany, companyId } = useAuth()
  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState<CompanyItem[]>()
  const [filtered, setFiltered] = useState<CompanyItem[]>()
  const { data, loading: companiesLoading } = useDLE(CompanyResource.list, {
    order_by: 'name',
    status: 'active',
    page_number: 1,
    page_size: 25,
  })

  useEffect(() => {
    data && !companies && setCompanies(data)
    companies && setFiltered(companies)
  }, [data, companies])

  const onSearch = (value: string) => {
    if (value.length > 0) {
      setFiltered(companies?.filter((c) => c.name.toLowerCase().indexOf(value.toLowerCase()) > -1))
    } else {
      setFiltered(companies)
    }
  }

  const onCompanySelect = (id: number) => {
    setLoading(true)
    switchCompany(id)
      .then(() => {
        onSelect && onSelect()
        resetEntireStore()
        navigate('/home')
      })
      .finally(() => setLoading(false))
  }

  return (
    <Row justify={'center'} align={'middle'} style={{ height: '100%' }}>
      <Col>
        <Card
          className={withPrefix('login-card')}
          cover={
            <Typography.Title level={2} style={{ marginTop: 30, textAlign: 'center' }}>
              SELECT A COMPANY
            </Typography.Title>
          }
        >
          <Spin spinning={loading || companiesLoading}>
            <VerticalSpace size={24}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Input
                    autoFocus
                    onChange={(evt) => onSearch(evt.target.value)}
                    placeholder={'Search for a Company'}
                    size={'large'}
                    style={{ padding: 16 }}
                  />
                </Col>
              </Row>
              <Row className={withPrefix('company-select-wrapper')}>
                {filtered?.map((company) => (
                  <Card
                    key={company.id}
                    hoverable={company.id !== companyId}
                    className={withPrefix(
                      'company-select-card',
                      company.id === companyId ? 'company-select-card-disabled' : '',
                    )}
                    onClick={() => company.id !== companyId && onCompanySelect(company.id)}
                  >
                    <HorizontalSpace justify={'space-between'}>
                      <HorizontalSpace align={'center'}>
                        <Badge status={company.status === 'active' ? 'success' : 'error'} text={''} />
                        <Typography.Paragraph style={{ marginBottom: 0 }}>
                          <b>{company.name}</b>
                        </Typography.Paragraph>
                      </HorizontalSpace>
                      {company.id === companyId && <Tag color={'success'}>Current</Tag>}
                    </HorizontalSpace>
                  </Card>
                ))}
              </Row>
            </VerticalSpace>
          </Spin>
        </Card>
      </Col>
    </Row>
  )
}

export default CompanySelect
