import { ApiEndpoint, ApiEntity, CreateApiResource } from "./ApiResource"

export type UserRole = {
    readonly id: number
    readonly name: string
    readonly description: string
}

class User extends ApiEntity {
    readonly id: number = 0
    readonly last_accessed: string = ''
    readonly first_name: string = ''
    readonly last_name: string = ''
    readonly full_name: string = ''
    readonly mobile_number: string = ''
    readonly email: string = ''
    readonly username: string = ''
    readonly status: string = ''
    readonly created_on: string = ''
    readonly modified_on: string = ''
}

class CompanyUser extends ApiEntity {
    readonly id: number = 0
    readonly user_id: number = 0
    readonly company_id: number = 0
    readonly role?: UserRole
    readonly status: string = 'active'
    readonly user: User = User.fromJS()

    static schema = {
        user: User
    }
}

const UserResourceBase = CreateApiResource({
    path: '/users/:id',
    schema: User
});

const ListUsers = new ApiEndpoint({
    path: '/users',
    schema: [CompanyUser]
})

const getCurrentUser = new ApiEndpoint({
    path: '/users',
    schema: User
})

const UserResource = {
    ...UserResourceBase,
    list: ListUsers,
    getCurrentUser
};

export { User, CompanyUser, UserResource }
