import locale from 'antd/es/locale/en_US'
import { ConfigProvider as AntProvider, App } from 'antd'
import classNames from 'classnames'

const ConfigProvider = ({ children }) => {
  return (
    <AntProvider
      locale={locale}
      prefixCls={'pv'}
      theme={{
        hashed: true,
        token: {
          colorPrimary: '#57806e',
          borderRadius: 0,
        },
      }}
    >
      <App>{children}</App>
    </AntProvider>
  )
}

export const withPrefix: (...names: string[]) => string = (...names) => {
  const key = 'pv'
  return classNames(
    names.filter((value) => !!value.trim()).map((name) => (!name.startsWith(key) ? `${key}-${name}` : name)),
  ).trim()
}

export default ConfigProvider
